import { Department } from 'src/app/models/department';
import { User } from './user';

export class Organisation {
  id:number=0;
  name:string='';
  code:string='';
  enabled:boolean=true;
  departments:Department[]=[];
  users:User[]=[];
  deleted_at:Date;

  constructor(organisation?)
  {
    if(organisation)
    {
      this.id=organisation.id;
      this.name=organisation.name;
      this.code=organisation.code;
      this.enabled=organisation.enabled;
      if(organisation.departments)
      {
        organisation.departments.forEach(d => {
          this.departments.push(new Department(d));
        });
      }
      if(organisation.users)
      {
        organisation.users.forEach(u => {
          this.users.push(new User(u));
        });
      }
      this.deleted_at=organisation.deleted_at;
    }
  }
}
