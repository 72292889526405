export class Address {
  id:number=0;
  label:string='Main';
  address_line_1:string='';
  address_line_2:string='';
  address_line_3:string='';
  town:string='';
  county:string='';
  postcode:string='';
  iso_country_code:string='GBR';

  constructor(address?)
  {
    if(address)
    {
      this.id = address.id;
      this.label = address.label;
      this.address_line_1 = address.address_line_1;
      this.address_line_2 = address.address_line_2;
      this.address_line_3 = address.address_line_3;
      this.town = address.town;
      this.county = address.county;
      this.postcode = address.postcode;
      this.iso_country_code = address.iso_country_code;
    }
  }
}
