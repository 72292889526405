import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Address } from 'src/app/models/address';
import { AddressService } from 'src/app/services/address.service';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.page.html',
  styleUrls: ['./add-address.page.scss'],
})
export class AddAddressPage implements OnInit {
  public newAddress:Address=new Address();
  @Input() company_id:number;

  constructor(
    private _modalCtrl: ModalController,
    private _feedback: FeedbackService,
    private _address: AddressService
  ) { }

  ngOnInit() {
  }

  public dismissCancel()
  {
    this._modalCtrl.dismiss();
  }

  public dismissSave()
  {
    if(
      this.newAddress.label == ''
      || this.newAddress.address_line_1 == ''
    ) return this._feedback.setShowMessage(false, 'Please enter at least a label and first line of the address');
    this._feedback.setShowWorking();
    this._address.createAddress(this.newAddress, this.company_id).subscribe(
      (res:any)=>{
        this._feedback.unsetShowWorking();
        if(res)
        {
          this._feedback.setShowMessage(true, 'New address added');
        }
        this._modalCtrl.dismiss({
          reload:true
        });
      }
    );
  }

}
