export class Contact {
  id:number=0;
  salutation:string='Mr';
  first_name:string='';
  last_name:string='';
  job_title:string='';
  email:string='';
  fake_email:boolean=false;
  telephone:string='';

  constructor(contact?)
  {
    if(contact)
    {
      this.id = contact.id;
      this.salutation = contact.salutation;
      this.first_name = contact.first_name;
      this.last_name = contact.last_name;
      this.job_title = contact.job_title;
      this.email = contact.email;
      this.fake_email = contact.fake_email;
      this.telephone = contact.telephone;
    }
  }
}
