import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Contact } from '../models/contact';
import { FeedbackService } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(
    private _http: HttpClient,
    private _feedback:FeedbackService
  ) { }

  public createContact(contact:Contact, company_id:number=0):Observable<Contact>
  {
    return this._http.post(environment.apiUrl+`contacts`, {
      contact:contact,
      company_id:company_id
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.contact
          )
          {
            return new Contact(res.contact);
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public updateContact(contact:Contact):Observable<Contact>
  {
    return this._http.put(environment.apiUrl+`contact/${contact.id}`, {
      contact:contact
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.contact
          )
          {
            return new Contact(res.contact);
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public deleteContact(id:number):Observable<boolean>
  {
    return this._http.delete(environment.apiUrl+`contact/${id}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return true;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }
}
