import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Organisation } from '../models/organisation';
import { FeedbackService } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(
    private _http: HttpClient,
    private _feedback:FeedbackService
  ) { }

  public getOrganisation(id:number):Observable<Organisation>
  {
    return this._http.get(environment.apiUrl+`organisation/${id}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.organisation
          )
          {
            return new Organisation(res.organisation);
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return new Organisation();
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public getOrganisations():Observable<Organisation[]>
  {
    return this._http.get(environment.apiUrl+`organisations`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.organisations
          )
          {
            let orgs:Organisation[]=[];
            res.organisations.forEach(o => {
              orgs.push(new Organisation(o));
            });
            return orgs;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return [];
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public addOrganisation(org:Organisation):Observable<Organisation[]>
  {
    return this._http.post(environment.apiUrl+`organisations`, {
      organisation: org
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.organisations
          )
          {
            let orgs:Organisation[]=[];
            res.organisations.forEach(o => {
              orgs.push(new Organisation(o));
            });
            return orgs;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return [];
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public updateOrganisation(org:Organisation):Observable<any>
  {
    return this._http.put(environment.apiUrl+`organisations/${org.id}`, {
      organisation:org
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.organisation
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public deleteOrganisation(id:number):Observable<any>
  {
    return this._http.delete(environment.apiUrl+`organisations/${id}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public reinstateOrganisation(id:number):Observable<any>
  {
    return this._http.put(environment.apiUrl+`organisations/reinstate`, {
      id:id
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }
}
