import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Company } from '../models/company';
import { FeedbackService } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private _http: HttpClient,
    private _feedback:FeedbackService
  ) { }

  public updateBaseInfo(accounts_email:string, accounts_phone:string, sales_email:string, id:number):Observable<any>
  {
    return this._http.put(environment.apiUrl+`companyBaseInfo/${id}`, {
      accounts_email:accounts_email,
      accounts_phone:accounts_phone,
      sales_email:sales_email
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public getCompanies():Observable<any>
  {
    return this._http.get(environment.apiUrl+`companies`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }
}
