import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { FeedbackService } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _http: HttpClient,
    private _feedback:FeedbackService
  )
  { }

  public getUsers():Observable<User[]>
  {
    return this._http.get(environment.apiUrl+`users`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.users
          )
          {
            let users:User[]=[];
            res.users.forEach(u => {
              users.push(new User(u));
            });
            return users;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return [];
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public getUser(userId:number):Observable<User>
  {
    return this._http.get(environment.apiUrl+`user/${userId}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.user
          )
          {
            return new User(res.user);
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return new User();
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public getUser2FA():Observable<any>
  {
    return this._http.get(environment.apiUrl+`my2FA`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public setUser2FA(enabled:boolean):Observable<any>
  {
    return this._http.put(environment.apiUrl+`my2FA`, {
      enabled: enabled
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public createNewUser(user:User, pass:string, pin:string):Observable<any>
  {
    return this._http.post(environment.apiUrl+`users`, {
      user:user,
      pass:pass,
      pin:pin
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public updateUser(user:User, newPass:string, newPin:string):Observable<any>
  {
    return this._http.put(environment.apiUrl+`user/${user.id}`, {
      user:user,
      newPass:newPass,
      newPin:newPin
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public deleteUser(id:number):Observable<any>
  {
    return this._http.delete(environment.apiUrl+`user/${id}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public reinstateUser(id:number):Observable<any>
  {
    return this._http.put(environment.apiUrl+`user/reinstate`, {
      id:id
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public validateUser(user:User, pass:string, pin:string, newUser:boolean)
  {
    if(!user.first_name)
    {
      this._feedback.setShowMessage(false, 'First name is required');
      return false;
    }
    if(!user.last_name)
    {
      this._feedback.setShowMessage(false, 'Last name is required');
      return false;
    }
    if(!user.email)
    {
      this._feedback.setShowMessage(false, 'Email address is required, even if it is fake and only for login purposes');
      return false;
    }
    if(user.organisations.length < 1)
    {
      this._feedback.setShowMessage(false, 'A user needs to be assigned to at least 1 organisation');
      return false;
    }
    let minRole:boolean=false;
    user.roles.forEach(r=>{
      if(r.name=='Customer' || r.name=='User') minRole=true;
    });
    if(!minRole)
    {
      this._feedback.setShowMessage(false, 'At a minimum either the \'Customer\' or \'User\' role must be selected');
      return false;
    }
    if(newUser)
    {
      if(!pin || pin.length != 4)
      {
        this._feedback.setShowMessage(false, 'A 4 digit PIN must be set for a new user');
        return false;
      }
      if(!pass || pass.length < 8)
      {
        this._feedback.setShowMessage(false, 'A password of at least 8 characters must be set for a new user');
        return false;
      }
    }
    return true;
  }
}
