export class Department {
  id:number=0;
  name:string='';
  deleted_at:Date;

  constructor(department?)
  {
    if(department)
    {
      this.id=department.id;
      this.name=department.name;
      this.deleted_at=department.deleted_at;
    }
  }
}
