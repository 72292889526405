import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Company } from '../models/company';
import { FeedbackService } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    private _http: HttpClient,
    private _feedback:FeedbackService
  ) { }

  public getCustomers():Observable<Company[]>
  {
    return this._http.get(environment.apiUrl+`customers`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.customers
          )
          {
            let customers=[];
            res.customers.forEach(customer => {
              customers.push(new Company(customer));
            });
            return customers;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return [];
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public getNonLeadCustomers():Observable<Company[]>
  {
    return this._http.get(environment.apiUrl+`non-lead-customers`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.customers
          )
          {
            let customers=[];
            res.customers.forEach(customer => {
              customers.push(new Company(customer));
            });
            return customers;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return [];
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public getCustomer(id:number):Observable<Company>
  {
    return this._http.get(environment.apiUrl+`customers/${id}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return new Company(res.customer);
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return new Company();
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public createCustomer(customer:Company):Observable<any>
  {
    return this._http.post(environment.apiUrl+`customers`, {
      customer:customer
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }
}
