import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Contact } from 'src/app/models/contact';
import { ContactsService } from 'src/app/services/contacts.service';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.page.html',
  styleUrls: ['./add-contact.page.scss'],
})
export class AddContactPage implements OnInit {
  public newContact:Contact=new Contact();
  @Input() company_id:number;

  constructor(
    private _modalCtrl: ModalController,
    private _feedback: FeedbackService,
    private _contact:ContactsService
  )
  { }

  ngOnInit() {
  }

  public dismissCancel()
  {
    this._modalCtrl.dismiss();
  }

  public dismissSave()
  {
    if(
      this.newContact.first_name == ''
      || this.newContact.last_name == ''
    ) return this._feedback.setShowMessage(false, 'Please enter at least a first and last name');
    this._feedback.setShowWorking();
    this._contact.createContact(this.newContact, this.company_id).subscribe(
      (res:any)=>{
        this._feedback.unsetShowWorking();
        if(res)
        {
          this._feedback.setShowMessage(true, 'New contact added');
        }
        this._modalCtrl.dismiss({
          reload:true
        });
      }
    );
  }
}
