import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Address } from '../models/address';
import { FeedbackService } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private _http: HttpClient,
    private _feedback:FeedbackService
  ) { }

  public createAddress(address:Address, company_id:number=0):Observable<Address>
  {
    return this._http.post(environment.apiUrl+`addresses`, {
      address:address,
      company_id:company_id
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.address
          )
          {
            return new Address(res.address);
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public updateAddress(address:Address):Observable<Address>
  {
    return this._http.put(environment.apiUrl+`addresses/${address.id}`, {
      address:address
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.address
          )
          {
            return new Address(res.address);
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public deleteAddress(id:number):Observable<boolean>
  {
    return this._http.delete(environment.apiUrl+`addresses/${id}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return true;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }
}
