import { Company } from "./company";
import { Contact } from "./contact";
import { User } from "./user";

export class Contact_Log {
  id:number;
  created_at:Date;
  user_id:number=0;
  contact_id:number=0;
  company_id:number=0;
  content:string='';
  user:User = new User();
  contact:Contact = new Contact();
  company:Company = new Company();

  constructor(log?)
  {
    if(log)
    {
      this.id = log.id;
      this.created_at = new Date(log.created_at);
      this.user_id = log.user_id;
      this.contact_id = log.contact_id;
      this.company_id = log.company_id;
      this.content = log.content;
      this.user = new User(log.user);
      this.contact = new Contact(log.contact);
      this.company = new Company(log.company);
    }
  }
}
