import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { CustomersService } from 'src/app/services/customers.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import { LeadsService } from 'src/app/services/leads.service';

@Component({
  selector: 'app-edit-lead',
  templateUrl: './edit-lead.page.html',
  styleUrls: ['./edit-lead.page.scss'],
})
export class EditLeadPage implements OnInit {
  @Input() lead:Company;
  private editValid:boolean=true;

  constructor(
    private _leads:LeadsService,
    private _customers:CustomersService,
    private _modalCtrl:ModalController,
    private _feedback:FeedbackService,
    private _router:Router,
    private _company:CompanyService
  )
  {
    // shiz
  }

  ngOnInit() {
  }

  public dismissCancel()
  {
    this._modalCtrl.dismiss();
  }

  public dismissSave()
  {
    this._feedback.setShowWorking();
    this._company.updateBaseInfo(this.lead.accounts_email, this.lead.accounts_phone, this.lead.sales_email, this.lead.id).subscribe(
      res => {
        if(res)
        {
          this._feedback.setShowMessage(true, 'Company info updated');
          this._feedback.unsetShowWorking();
          this._modalCtrl.dismiss({
            reload:true
          });
        }
      }
    );
  }
}
