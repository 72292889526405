import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Department } from '../models/department';
import { FeedbackService } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(
    private _http: HttpClient,
    private _feedback:FeedbackService
  ) { }

  public getDepartment(id:number):Observable<Department>
  {
    return this._http.get(environment.apiUrl+`department/${id}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.department
          )
          {
            return new Department(res.department);
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return [];
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public getDepartments():Observable<Department[]>
  {
    return this._http.get(environment.apiUrl+`departments`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.departments
          )
          {
            let depts:Department[]=[];
            res.departments.forEach(d => {
              depts.push(new Department(d));
            });
            return depts;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return [];
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public addDepartment(dept:Department):Observable<Department[]>
  {
    return this._http.post(environment.apiUrl+`departments`, {
      department: dept
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
            && res.departments
          )
          {
            let depts:Department[]=[];
            res.departments.forEach(d => {
              depts.push(new Department(d));
            });
            return depts;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return [];
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public updateDepartment(dept:Department):Observable<any>
  {
    return this._http.put(environment.apiUrl+`department/${dept.id}`, {
      department:dept
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public deleteDepartment(id:number):Observable<any>
  {
    return this._http.delete(environment.apiUrl+`departments/${id}`).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public reinstateDepartment(id:number):Observable<any>
  {
    return this._http.put(environment.apiUrl+`departments/reinstate`, {
      id:id
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }
}
