import { Address } from './address';
import { Contact } from './contact';
import { Contact_Log } from './contact_log';

export class Company {
  id:number=0;
  name:string='';
  parent:number=0;
  organisation_id:number=0;
  lead:boolean=true;
  customer:boolean=false;
  supplier:boolean=false;
  group:boolean=false;
  status:string='pending';
  deleted_at:Date;
  created_at:Date;
  updated_at:Date;
  parent_co:Company;
  contact_logs:Contact_Log[] = [];
  contacts:Contact[] = [];
  addresses:Address[] = [];
  accounts_email:string='';
  accounts_phone:string='';
  sales_email:string='';
  data_updates:any[]=[];

  constructor(company?)
  {
    if(company)
    {
      this.id = company.id;
      this.name = company.name;
      this.parent = company.parent;
      this.organisation_id = company.organisation_id;
      this.lead = company.lead;
      this.customer = company.customer;
      this.supplier = company.supplier;
      this.group = company.group;
      this.status = company.status;
      this.deleted_at = company.deleted_at;
      this.created_at = new Date(company.created_at);
      this.updated_at = new Date(company.updated_at);
      this.parent_co = new Company(company.parent_co);
      if(company.contact_logs)
      {
        company.contact_logs.forEach(log=>{
          this.contact_logs.push(new Contact_Log(log));
        });
      }
      if(company.contacts)
      {
        company.contacts.forEach(contact=>{
          this.contacts.push(new Contact(contact));
        });
      }
      if(company.addresses)
      {
        company.addresses.forEach(address=>{
          this.addresses.push(new Address(address));
        });
      }
      this.accounts_email= company.accounts_email;
      this.accounts_phone= company.accounts_phone;
      this.sales_email= company.sales_email;
      if(company.data_updates)
      {
        company.data_updates.forEach(update=>{
          this.data_updates.push(JSON.parse(update));
        });
      }
    }
  }
}
