import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-log',
  templateUrl: './view-log.page.html',
  styleUrls: ['./view-log.page.scss'],
})
export class ViewLogPage implements OnInit {

  constructor(
    private _modalCtrl:ModalController
  ) { }

  ngOnInit() {
  }

  public dismissCancel()
  {
    this._modalCtrl.dismiss();
  }
}
