import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Contact } from 'src/app/models/contact';
import { ContactsService } from 'src/app/services/contacts.service';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-view-contact',
  templateUrl: './view-contact.page.html',
  styleUrls: ['./view-contact.page.scss'],
})
export class ViewContactPage implements OnInit {
  @Input() contact:Contact;

  constructor(
    private _modalCtrl: ModalController,
    private _feedback: FeedbackService,
    private _contact:ContactsService
  )
  { }

  ngOnInit() {
  }

  public dismissCancel()
  {
    this._modalCtrl.dismiss();
  }

  public dismissDelete()
  {
    if(confirm('Confirm deletion of this contact...'))
    {
      this._contact.deleteContact(this.contact.id).subscribe(
        res => {
          if(res)
          {
            this._feedback.setShowMessage(true, 'Contact deleted');
            this._modalCtrl.dismiss({
              reload:true
            });
          }
        }
      );
    }
  }

  public dismissSave()
  {
    if(
      this.contact.first_name == ''
      || this.contact.last_name == ''
    ) return this._feedback.setShowMessage(false, 'Please enter at least a first and last name');
    this._feedback.setShowWorking();
    this._contact.updateContact(this.contact).subscribe(
      (res:any)=>{
        this._feedback.unsetShowWorking();
        if(res)
        {
          this._feedback.setShowMessage(true, 'Contact saved');
        }
        this._modalCtrl.dismiss({
          reload:true
        });
      }
    );
  }
}
